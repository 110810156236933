@import "./mixins/breakpoints.scss";

#desktop {
  background-image: url(./assets/background.png);
  background-size: cover;
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  position: relative;

  nav {
    z-index: 5;
    position: absolute;
    top: 0;

    height: 32px;
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.2);

    .name {
      font-weight: bold;
    }
    ul {
      flex: 1;
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;

      li {
        padding: 0px 30px;

        a {
          text-decoration: none;
          color: black;
        }
      }
    }
    time {
    }
  }

  #window-container {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
    padding: 10vh 10vw;
    box-sizing: border-box;

    @include phone() {
      padding: 5vh 5vw;
    }

    #window {
      border-radius: 12px;
      overflow: hidden;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);

      display: flex;
      flex-direction: column;

      #title-bar {
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.3);

        .title {
          color: white;
          font-weight: bold;
          font-size: 14px;
        }
      }

      #term {
        font-family: "Fira Code", "Courier New", Courier, monospace;
        padding: 14px;
        flex: 1;
        overflow-x: auto;
        overflow-y: auto;
        line-height: 21px;

        a {
          color: aqua;
        }
      }
    }
  }
}
