.project-item {
  overflow: hidden;
  margin-bottom: 20px;

  .project-item-thumbnail {
    float: left;
    height: 100px;
    margin-right: 20px;

    img {
      height: 100%;
      width: auto;
    }
  }

  .project-item-links {
    list-style-type: none;

    li {
      display: inline-block;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
